import { assertResponseSuccess } from 'providers/functions';
import { CosmosCreateProvidersParams, httpClient } from '../CosmosRAProvidersContext';
import { BlockLayoutResponse } from 'utility/types/dataProvider';

export const createBlockLayoutProvider = ({
  apiUrl,
  authToken,
  defaultSite,
  stringifyOptions,
  queryClient,
}: CosmosCreateProvidersParams) => ({
  // Create new block layout for a URL
  createBlockLayout: async (
    map_id: string,
    url_id: string,
    data: BlockLayoutResponse,
  ): Promise<BlockLayoutResponse> => {
    try {
      const url = `${apiUrl}/core/layout/${map_id}/${url_id}/`;

      const { json, status } = await httpClient({
        url: url,
        options: {
          method: 'POST',
          body: JSON.stringify(data),
          headers: { 'Content-Type': 'application/json' },
        },
        authToken: authToken,
        defaultSite: defaultSite,
      });
      await assertResponseSuccess(json, status);

      return json.data;
    } catch (error) {
      console.error('[dataProvider] createBlockLayout() error:' + error?.toString());
      throw error;
    }
  },

  // Retrieve the active block layout for a URL
  getActiveBlockLayout: async (
    map_id: string,
    url_id: string,
  ): Promise<BlockLayoutResponse> => {
    try {
      const url = `${apiUrl}/core/layout/${map_id}/${url_id}/active/`;

      const { json, status } = await httpClient({
        url: url,
        authToken: authToken,
        defaultSite: defaultSite,
      });

      await assertResponseSuccess(json, status);

      return json.data;
    } catch (error) {
      console.error('[dataProvider] getActiveBlockLayout() error:' + error?.toString());
      throw error;
    }
  },

  // Retrieve a block layout for a specific URL region
  getBlockLayoutForRegion: async (
    map_id: string,
    url_id: string,
    region_name: string,
  ): Promise<BlockLayoutResponse> => {
    try {
      const url = `${apiUrl}/core/layout/${map_id}/${url_id}/active/${region_name}/`;

      const { json, status } = await httpClient({
        url: url,
        authToken: authToken,
        defaultSite: defaultSite,
      });

      await assertResponseSuccess(json, status);

      return json.data;
    } catch (error) {
      console.error(
        '[dataProvider] getBlockLayoutForRegion() error:' + error?.toString(),
      );
      throw error;
    }
  },

  publishBlockLayout: async (
    map_id: string,
    url_id: string,
    layout_id: string,
  ): Promise<BlockLayoutResponse> => {
    try {
      const url = `${apiUrl}/core/layout/${map_id}/${url_id}/${layout_id}/publish/`;

      const { json, status } = await httpClient({
        url: url,
        options: {
          method: 'PUT',
        },
        authToken: authToken,
        defaultSite: defaultSite,
      });

      await assertResponseSuccess(json, status);
      return json;
    } catch (error) {
      console.error('[dataProvider] publishBlockLayout() error:' + error?.toString());
      throw error;
    }
  },
});
