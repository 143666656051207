import { flatten } from 'flat';
import { FullErrorResponse } from '../utility/types/dataProvider';

// --- Helper Functions ---

/**
 * Flatten Objects for Square Bracket Syntax
 * https://github.com/hughsk/flat
 * e.g.  "preview[display]: "750w", handle[deep][nested][values]: "yes"
 **/
export function flattenObject<T extends Record<string, any>>(
  input: T,
  bracketed = true,
): Record<string, string> {
  const flattened = flatten<T, Record<string, any>>(input, {
    delimiter: '.',
    safe: true,
  });

  // Optionally return early without bracketed syntax
  if (!bracketed) {
    return flattened;
  }

  // Post-process each flattened key to convert dots into bracket notation
  // Example: "preview.size1" → "preview[size1]"
  const formatted = Object.fromEntries(
    Object.entries(flattened).map(([flatKey, value]) => {
      const bracketedKey = flatKey
        .split('.')
        .reduce((acc, segment, i) => (i === 0 ? segment : `${acc}[${segment}]`), '');
      return [bracketedKey, String(value)];
    }),
  );

  return formatted;
}

/** Process related content/children */
export function relatedContent(params: any) {
  if (!params.content) {
    return params;
  }
  const htmlString = params.content;
  // Create a new DOMParser
  const parser = new DOMParser();
  // Parse the HTML string to create an HTMLElement
  const doc = parser.parseFromString(htmlString, 'text/html');
  const element = doc.documentElement;
  const bloxInlineElements = element.querySelectorAll('blox-inline');
  const extractedData = [];
  for (const bloxInlineElement of bloxInlineElements) {
    if (bloxInlineElement.getAttribute('placeholder') === 'true') {
      continue; // Skip processing if placeholder is true
    }

    extractedData.push({
      uuid: bloxInlineElement.getAttribute('uuid'),
      app: bloxInlineElement.getAttribute('app'),
      type: 'child',
    });
  }

  const children = params.children
    ? [...params.children, ...extractedData]
    : extractedData;

  const transformedData = {
    ...params,
    children: children,
  };

  return transformedData;
}

/** Handle TOC */
export function stripTOC(params: any) {
  if (!params.content) {
    return params;
  }
  const htmlString = params.content;
  // Create a new DOMParser
  const parser = new DOMParser();
  // Parse the HTML string to create an HTMLElement
  const doc = parser.parseFromString(htmlString, 'text/html');
  // Get the root element of the document
  const elements = doc.documentElement;

  // Get all elements in the DOM
  const allElements = elements.querySelectorAll('*');

  // Iterate through each element
  allElements.forEach(function (element) {
    // Remove ID attribute
    element.removeAttribute('id');
    // Remove data-toc-id attribute
    element.removeAttribute('data-toc-id');
    // Remove data-toc-uuid attribute
    element.removeAttribute('data-toc-uuid');
    // Remove TipTap Inline Asset attributes so erroneous data is not saved
    element.removeAttribute('asset');
    // Remove fullPayload attribute
    element.removeAttribute('fullPayload');
    // Remove newInsert attribute
    element.removeAttribute('newInsert');
    // Remove hasRelated attribute
    element.removeAttribute('hasRelated');
  });

  // Serialize the modified document back to HTML string
  const modifiedHtmlString = elements.outerHTML;

  // Return the modified content
  return {
    ...params,
    content: modifiedHtmlString,
  };
}

/** Determine the items unique id for React-Admin's key prop */
export function determineUniqueId(item: any, resource: string) {
  switch (resource) {
    case 'editorial': {
      return item?.uuid;
    }
    case 'user': {
      // React-Admin Unique Key workaround - Concatenate subscription ID if exists.
      // /user/account/search could return multiple users with same UUID, but different subscription IDs rows
      // when payload has subscription == true in query, or view_by subscription, so concat if exists to get unique.
      const uniqueId = `${item?.uuid}${item?.subscription?.id || ''}`;
      return uniqueId;
    }
    case 'user/activities': {
      return item?.id; // This endpoint uses id as unique key.
    }
    case 'savedsearch': {
      return item?.id; // This endpoint uses id as unique key.
    }
    case 'experiments': {
      return item?.name; // This endpoint uses id as unique key.
    }
    default: {
      console.warn('[determineUniqueId] - defaulted!');
      return item?.uuid || item?.id || undefined;
    }
  }
}

export const createErrorResponse = (json: any): FullErrorResponse => {
  return {
    status: json?.status,
    name: json?.name,
    message: json?.message,
    body: json,
    ...json, // Spread any additional info in the json response.
  };
};

/** Assert the API responded with successful fetch status
 * @param {any} json - The JSON response from the API
 * @param {number} status - The HTTP status code
 * @param {string} [body] - The response body as a string
 * @param {Headers} [headers] - The response headers
 * @throws {FullErrorResponse} - Throws the full error response object
 **/
export async function assertResponseSuccess(
  json: any,
  status: number,
  body?: string,
  headers?: Headers,
) {
  // Anything in range 200-299 should be considered a success e.g. 200, 204, etc.
  if (status < 200 || status > 299) {
    throw createErrorResponse(json);
  }

  // Some endpoints return 204 code on success, with no acutal json.status
  // response so we need to check for that and return early.
  if (status === 204) {
    return;
  }

  // Otherwise check the status string on the json
  if (json?.status && json?.status !== 'success') {
    throw createErrorResponse(json);
  }
}

export const commonPreviewPayload = {
  preview: {
    size1: '550w',
    size2: '750w',
    thumbnail: '550w',
    display: '750w',
  },
};

export const getResourceDetails = (
  resource: string, // 'editorial, 'user', etc..
) => {
  let baseResource = resource;
  let subResource = '';
  let isSearch = 'search/';
  let additionalPayload: any = {};
  let modifyPayload: ((payload: any) => void) | null = null;

  switch (resource) {
    case 'editorial':
      subResource = 'asset';

      additionalPayload = {
        ...commonPreviewPayload,
      };

      break;
    case 'user':
      subResource = 'account';
      break;
    case 'user/activities':
      baseResource = 'user';
      subResource = 'activities';
      modifyPayload = (payload) => {
        delete payload.sort;
        delete payload.dir;

        return payload;
      };
      break;
    case 'savedsearch':
      baseResource = 'core';
      subResource = 'savedsearch';
      modifyPayload = (payload) => {
        delete payload.sort;
        delete payload.dir;

        return payload;
      };
      break;
    case 'experiments':
      baseResource = 'core';
      subResource = 'experiment';
      isSearch = '';
      modifyPayload = (payload) => {
        delete payload.sort;
        delete payload.dir;

        return payload;
      };
      break;
    default:
      console.warn('[getResourceDetails] - No resourceType:', resource);
      break;
  }

  return {
    baseResource,
    subResource,
    isSearch,
    additionalPayload,
    modifyPayload,
  };
};
