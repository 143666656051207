import { assertResponseSuccess, flattenObject } from 'providers/functions';
import { CosmosCreateProvidersParams, httpClient } from '../CosmosRAProvidersContext';
import { fetchUtils } from 'react-admin';
import {
  CancelSubscriptionByIdParams,
  CancelSubscriptionByIdResult,
  CreateSubscriptionParams,
  CreateSubscriptionResult,
  GetServiceByIdParams,
  GetServiceByIdResult,
  GetServiceRatesResult,
  GetServiceTypesParams,
  GetServiceTypesResult,
  GetServicesParams,
  GetServicesResult,
  GetSubscriptionCancelReasonsResult,
  GetSubscriptionDataByIdParams,
  GetSubscriptionDataByIdResult,
  GetSubscriptionServiceByIdResult,
  GetSubscriptionServiceRatesCompareParams,
  GetSubscriptionServiceRatesCompareResult,
  GetUserSubscriptionTransactionsByIdParams,
  GetUserSubscriptionTransactionsByIdResult,
  GetUserSubscriptionsByIdParams,
  GetUserSubscriptionsByIdResult,
  RefundSubscriptionTransactionParams,
  RefundSubscriptionTransactionResult,
  ServiceItem,
  ServiceRate,
  ServiceTypeItem,
  SetSubscriptionChangeRateParams,
  SetSubscriptionChangeRateResult,
  SetSubscriptionDataByIdParams,
  SetSubscriptionDataByIdResult,
  TaxQuoteRequest,
  TaxQuoteResponse,
} from 'utility/types/dataProvider';

// -----------------------------------------------
// ------------ SUBSCRIPTIONS PROVIDER -----------
// -----------------------------------------------
export const createSubscriptionsProvider = ({
  apiUrl,
  authToken,
  defaultSite,
  stringifyOptions,
  queryClient,
}: CosmosCreateProvidersParams) => ({
  // Get site subscription services
  getServices: async (params: GetServicesParams): Promise<GetServicesResult> => {
    try {
      const payload = {
        ...params,
      };
      const url = `${apiUrl}/subscription/services/?${fetchUtils.queryParameters(
        payload,
        stringifyOptions,
      )}`;

      const { json, status } = await httpClient({
        url: url,
        options: {
          method: 'GET',
        },
        authToken: authToken,
        defaultSite: defaultSite,
      });

      await assertResponseSuccess(json, status);

      const result: GetServicesResult = {
        status: json.status,
        data: {
          total: json.data.total,
          next: json.data.next,
          prev: json.data.prev,
          items: json.data.items.map((item: ServiceItem) => ({ ...item })),
        },
      };
      return result;
    } catch (error) {
      console.error('[dataProvider] getServices() error:' + error?.toString());
      throw error;
    }
  },

  getServiceById: async (params: GetServiceByIdParams): Promise<GetServiceByIdResult> => {
    try {
      const url = `${apiUrl}/subscription/services/${params.id}/`;

      const { json, status } = await httpClient({
        url: url,
        options: {
          method: 'GET',
        },
        authToken: authToken,
        defaultSite: defaultSite,
      });

      await assertResponseSuccess(json, status);

      const result: GetServiceByIdResult = {
        status: json.status,
        data: json.data,
      };
      return result;
    } catch (error) {
      console.error('[dataProvider] getServiceById() error:' + error?.toString());
      throw error;
    }
  },

  // Get site subscription service types
  getServiceTypes: async (
    params: GetServiceTypesParams,
  ): Promise<GetServiceTypesResult> => {
    try {
      const payload = {
        ...(params.activated && { activated: params.activated }),
        ...(params.available && { available: params.available }),
        ...(params.query && { query: params.query }),
        ...(params.limit && { limit: params.limit > 100 ? 99 : params.limit }),
        ...(params.page && { page: params.page }),
      };
      const url = `${apiUrl}/subscription/services/types/?${fetchUtils.queryParameters(
        payload,
        stringifyOptions,
      )}`;

      const { json, status } = await httpClient({
        url: url,
        options: {
          method: 'GET',
        },
        authToken: authToken,
        defaultSite: defaultSite,
      });

      await assertResponseSuccess(json, status);

      const result: GetServiceTypesResult = {
        status: json.status,
        data: {
          total: json.data.total,
          next: json.data.next,
          prev: json.data.prev,
          items: json.data.items.map((item: ServiceTypeItem) => ({ ...item })),
        },
      };
      return result;
    } catch (error) {
      console.error('[dataProvider] getServices() error:' + error?.toString());
      throw error;
    }
  },

  // Get site subscription service rates
  getServiceRates: async (id: number): Promise<GetServiceRatesResult> => {
    try {
      const url = `${apiUrl}/subscription/services/${id}/rates/`;

      const { json, status } = await httpClient({
        url: url,
        options: {
          method: 'GET',
        },
        authToken: authToken,
        defaultSite: defaultSite,
      });

      await assertResponseSuccess(json, status);

      const result: GetServiceRatesResult = {
        status: json.status,
        data: {
          total: json.data.total,
          next: json.data.next,
          prev: json.data.prev,
          facets: json.data.facets,
          items: json.data.items.map((item: ServiceRate) => ({ ...item })),
        },
      };
      return result;
    } catch (error) {
      console.error('[dataProvider] getServiceRates() error:' + error?.toString());
      throw error;
    }
  },

  // Get subscription tax quote
  getTaxQuote: async (
    serviceId: string,
    quoteData: TaxQuoteRequest,
  ): Promise<TaxQuoteResponse> => {
    try {
      const url = `${apiUrl}/subscription/services/${serviceId}/quote/`;

      const { json, status } = await httpClient({
        url: url,
        options: {
          method: 'POST',
          body: JSON.stringify(quoteData),
        },
        authToken: authToken,
        defaultSite: defaultSite,
      });

      await assertResponseSuccess(json, status);

      const result: TaxQuoteResponse = {
        status: json.status,
        data: json.data,
      };
      return result;
    } catch (error) {
      console.error('[dataProvider] getTaxQuote() error:' + error?.toString());
      throw error;
    }
  },

  // Create subscription
  createSubscription: async (
    params: CreateSubscriptionParams,
  ): Promise<CreateSubscriptionResult> => {
    try {
      const url = `${apiUrl}/subscription/subscriptions/`;

      const { json, status } = await httpClient({
        url: url,
        options: {
          method: 'POST',
          body: JSON.stringify(params),
        },
        authToken: authToken,
        defaultSite: defaultSite,
      });

      await assertResponseSuccess(json, status);

      const result: CreateSubscriptionResult = {
        status: json.status,
        data: json.data,
      };
      return result;
    } catch (error) {
      console.error('[dataProvider] createSubscription() error:' + error?.toString());
      throw error;
    }
  },

  // Get /subscription/members/{id}/subscriptions
  getUserSubscriptionsById: async ({
    query,
    path,
  }: GetUserSubscriptionsByIdParams): Promise<GetUserSubscriptionsByIdResult> => {
    try {
      const pathId = path?.id;
      if (!pathId) {
        throw new Error('Missing required parameters!');
      }

      const page = query?.page;
      const limit = query?.limit || 99;
      const sort = query?.sort;

      const payload = {
        ...(page && { page: query?.page }),
        ...(limit && { limit: query?.limit || 99 }),
        ...(sort && { sort: query?.sort }),
      };

      const flatBracketPayload = flattenObject(payload, true);

      const url = `${apiUrl}/subscription/members/${pathId}/subscriptions/?${fetchUtils.queryParameters(
        flatBracketPayload,
        stringifyOptions,
      )}`;

      const { json, status } = await httpClient({
        url: url,
        options: {
          method: 'GET',
        },
        authToken: authToken,
        defaultSite: defaultSite,
      });

      await assertResponseSuccess(json, status);

      const result: GetUserSubscriptionsByIdResult = {
        status: json.status,
        data: { ...json.data },
      };
      return result;
    } catch (error) {
      console.error(
        '[getUserSubscriptionsById] getUserSubscriptionsById() error:' +
          error?.toString(),
      );
      throw error;
    }
  },

  // get /subscription/subscriptions/{id}/
  getSubscriptionDataById: async ({
    id,
  }: GetSubscriptionDataByIdParams): Promise<GetSubscriptionDataByIdResult> => {
    try {
      const url = `${apiUrl}/subscription/subscriptions/${id}/`;

      const { json, status } = await httpClient({
        url: url,
        options: {
          method: 'GET',
        },
        authToken: authToken,
        defaultSite: defaultSite,
      });

      await assertResponseSuccess(json, status);

      const result: GetSubscriptionDataByIdResult = {
        status: json?.status,
        data: { ...json?.data },
      };

      return result;
    } catch (error) {
      console.error(
        '[getSubscriptionDataById] getSubscriptionDataById() error:' + error?.toString(),
      );
      throw error;
    }
  },

  // post subscription/subscriptions/{id} (expire time update)
  setSubscriptionDataById: async ({
    id,
    expire_time,
  }: SetSubscriptionDataByIdParams): Promise<SetSubscriptionDataByIdResult> => {
    try {
      if (!id || !expire_time) {
        throw new Error('Missing required paramaters!');
      }

      const url = `${apiUrl}/subscription/subscriptions/${id}/?expire_time=${expire_time}`;

      const { json, status } = await httpClient({
        url: url,
        options: {
          method: 'POST',
        },
        authToken: authToken,
        defaultSite: defaultSite,
      });

      await assertResponseSuccess(json, status);

      // success is a 204 no content status
      const result: SetSubscriptionDataByIdResult = {
        status: status,
      };
      return result;
    } catch (error) {
      console.error(
        '[setSubscriptionDataById] setSubscriptionDataById() error:' + error?.toString(),
      );
      throw error;
    }
  },

  // get /subscription/subscriptions/{id}/transactions/
  getUserSubscriptionTransactionsById: async ({
    id,
    page,
    limit,
  }: GetUserSubscriptionTransactionsByIdParams): Promise<GetUserSubscriptionTransactionsByIdResult> => {
    try {
      const payload = {
        ...(page && { page: page }),
        ...(limit && { limit: limit > 100 ? 99 : limit }),
      };

      const url = `${apiUrl}/subscription/subscriptions/${id}/transactions/?${fetchUtils.queryParameters(
        payload,
        stringifyOptions,
      )}`;

      const { json, status } = await httpClient({
        url: url,
        options: {
          method: 'GET',
        },
        authToken: authToken,
        defaultSite: defaultSite,
      });

      await assertResponseSuccess(json, status);

      const result: GetUserSubscriptionTransactionsByIdResult = {
        status: json.status,
        data: { ...json.data },
      };

      return result;
    } catch (error) {
      console.error(
        '[getUserSubscriptionTransactionsById] getUserSubscriptionTransactionsById() error:' +
          error?.toString(),
      );
      throw error;
    }
  },

  // post - /subscription/subscriptions/{id}/cancel/?reason_code={reason_code}&reason={reason}
  cancelSubscriptionById: async ({
    id,
    reason_code,
    reason,
  }: CancelSubscriptionByIdParams): Promise<CancelSubscriptionByIdResult> => {
    try {
      if (!id || !reason_code) {
        throw new Error('Missing required paramaters!');
      }

      const url = new URL(
        `${apiUrl}/subscription/subscriptions/${id}/cancel/?reason_code=${reason_code}`,
      );

      if (reason) {
        url.searchParams.append('reason', reason?.trim());
      }

      const stringUrl = url?.toString();

      const { json, status } = await httpClient({
        url: stringUrl,
        options: {
          method: 'POST',
        },
        authToken: authToken,
        defaultSite: defaultSite,
      });

      await assertResponseSuccess(json, status);

      // success is a 204 no content status
      const result: CancelSubscriptionByIdResult = {
        status: status,
        data: json?.data,
      };

      return result;
    } catch (error) {
      console.error(
        '[cancelSubscriptionById] cancelSubscriptionById() error:' + error?.toString(),
      );
      throw error;
    }
  },

  // get - /subscription/introspection/cancel_reasons/
  getSubscriptionCancelReasons: async (): Promise<GetSubscriptionCancelReasonsResult> => {
    try {
      const url = `${apiUrl}/subscription/introspection/cancel_reasons/`;

      const { json, status } = await httpClient({
        url: url,
        options: {
          method: 'GET',
        },
        authToken: authToken,
        defaultSite: defaultSite,
      });

      await assertResponseSuccess(json, status);

      const result: GetSubscriptionCancelReasonsResult = {
        status: json.status,
        data: json.data,
      };

      return result;
    } catch (error) {
      console.error(
        '[getSubscriptionCancelReasons] getSubscriptionCancelReasons() error:' +
          error?.toString(),
      );
      throw error;
    }
  },

  // refund success is 204 - /subscription/subscriptions/{id}/transactions/{transaction_id}/refund/
  refundSubscriptionTransaction: async ({
    id,
    transactionId,
  }: RefundSubscriptionTransactionParams): Promise<RefundSubscriptionTransactionResult> => {
    try {
      if (!id || !transactionId) {
        throw new Error('Missing required parameters!');
      }

      const url = `${apiUrl}/subscription/subscriptions/${id}/transactions/${transactionId}/refund/`;

      const { json, status } = await httpClient({
        url: url,
        options: {
          method: 'POST',
        },
        authToken: authToken,
        defaultSite: defaultSite,
      });

      await assertResponseSuccess(json, status);

      // Return the status and data. Should be Status 204 if successful.
      const result: RefundSubscriptionTransactionResult = {
        status: status,
        data: json?.data,
      };

      return result;
    } catch (error) {
      console.error(
        '[refundSubscriptionTransaction] refundSubscriptionTransaction() error:' +
          error?.toString(),
      );
      throw error;
    }
  },

  // get /subscription/services/{id} - retrieve service data by id
  getSubscriptionServiceById: async (
    id: string,
  ): Promise<GetSubscriptionServiceByIdResult> => {
    try {
      if (!id) {
        throw new Error('Missing required parameters!');
      }
      const url = `${apiUrl}/subscription/services/${id}/`;

      const { json, status } = await httpClient({
        url: url,
        options: {
          method: 'GET',
        },
        authToken: authToken,
        defaultSite: defaultSite,
      });

      await assertResponseSuccess(json, status);

      const result: GetSubscriptionServiceByIdResult = {
        status: json.status,
        data: json.data,
      };
      return result;
    } catch (error) {
      console.error(
        '[getSubscriptionServiceById] getSubscriptionServiceById() error:' +
          error?.toString(),
      );
      throw error;
    }
  },

  // get subscription/services/{id}/rates/compare/
  getCompareSubscriptionServiceRates: async (
    params: GetSubscriptionServiceRatesCompareParams,
  ): Promise<GetSubscriptionServiceRatesCompareResult> => {
    const { id, rate_id, save_offers, allow_hidden, page, limit } = params;

    if (!id || !rate_id) {
      throw new Error('Missing required parameters!');
    }

    try {
      const url = new URL(
        `${apiUrl}/subscription/services/${id}/rates/compare/?` +
          new URLSearchParams({
            rate_id: rate_id,
            ...(save_offers !== undefined && { save_offers: String(save_offers) }),
            ...(allow_hidden !== undefined && { allow_hidden: String(allow_hidden) }),
            ...(page && { page: String(page) }),
            ...(limit && { limit: String(limit) }),
          }),
      );

      const stringUrl = url?.toString();

      const { json, status } = await httpClient({
        url: stringUrl,
        options: {
          method: 'GET',
        },
        authToken: authToken,
        defaultSite: defaultSite,
      });

      await assertResponseSuccess(json, status);

      const result: GetSubscriptionServiceRatesCompareResult = {
        status: json?.status,
        data: json?.data,
      };

      return result;
    } catch (error) {
      console.error(
        '[getCompareSubscriptionServiceRates] getCompareSubscriptionServiceRates() error:' +
          error?.toString(),
      );
      throw error;
    }
  },

  // subscription/subscriptions/{id}/change_rate/ - 204 success
  setSubscriptionChangeRate: async (
    params: SetSubscriptionChangeRateParams,
  ): Promise<SetSubscriptionChangeRateResult> => {
    try {
      const { id, rate_id } = params;

      if (!id || !rate_id) {
        throw new Error('Missing required parameters!');
      }

      const url = new URL(
        `${apiUrl}/subscription/subscriptions/${id}/change_rate/?rate_id=${rate_id}`,
      );

      const { json, status } = await httpClient({
        url: url?.toString(),
        options: {
          method: 'POST',
        },
        authToken: authToken,
        defaultSite: defaultSite,
      });

      await assertResponseSuccess(json, status);

      const result: SetSubscriptionChangeRateResult = {
        status: json?.status,
        data: json?.data,
      };

      return result;
    } catch (error) {
      console.error(
        '[setSubscriptionChangeRate] setSubscriptionChangeRate() error:' +
          error?.toString(),
      );
      throw error;
    }
  },
});
