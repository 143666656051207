import { Box, Typography } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import { memo } from 'react';
import { BloxNXTLogo } from '../../assets/BloxNXTLogo';
import { bloxColors, bloxValues } from '../../themes/constants';

interface BloxLoadingSplashProps {
  message?: string;
  loadingPrimary?: string; // Unused, react admin typescript workaround.
  loadingSecondary?: string; // Unused, react admin typescript workaround.
}

const BloxLoadingSplash = memo(({ message = 'Loading...' }: BloxLoadingSplashProps) => {
  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '8px',
        width: '100vw',
        height: '100vh',
        backgroundColor: theme.palette.background.default,
      })}
    >
      <BloxNXTLogo sx={{ height: 'auto', width: '250px' }} />

      {message ? (
        <Typography
          sx={(theme) => ({
            color: theme.palette.text.secondary,
            fontSize: bloxValues.regularFontSizeRem,
            textAlign: 'center',
          })}
        >
          {message}
        </Typography>
      ) : null}

      <LinearProgress
        variant="indeterminate"
        sx={{
          height: '4px',
          width: '100%',
          backgroundColor: bloxColors.white,
          '.MuiLinearProgress-barColorPrimary': {
            backgroundColor: bloxColors.logoblue,
          },
        }}
      />
    </Box>
  );
});
BloxLoadingSplash.displayName = 'BloxLoadingSplash';

export default BloxLoadingSplash;
