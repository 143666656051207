import {
  createContext,
  memo,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { AuthContextProps, useAuth } from 'react-oidc-context';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import BloxLoadingSplash from '../../components/generic/BloxLoadingSplash';
import ErrorAlerts, { ErrorDetails } from '../../components/generic/ErrorAlerts';
import NoCosmosPermissions from '../../components/generic/NoCosmosPermissions';
import { capitalizeFirstLetter } from '../../utility/functions';
import {
  ApiErrorResponse,
  GetUserMeFullItem,
  SessionConfigResult,
  UserAccountGroups,
} from '../../utility/types/dataProvider';
import { EnvData, useCosmosAuthEnv } from './CosmosAuthEnvContext';
import { User } from 'oidc-client-ts';
import { createSessionsProvider } from './extensions/Sessions';
import {
  CosmosCreateProvidersParams,
  stringifyOptions,
} from './CosmosRAProvidersContext';
import { createUsersProvider } from './extensions/Users';
import { useSessionStorage } from '@mantine/hooks';
import { flushSync } from 'react-dom';

//----------------------------------------------------------------------
// Types and Consts
//----------------------------------------------------------------------
export interface CosmosContextType {
  changeActiveSiteAsync: (newDefaultSite: string) => Promise<string>;
  clearInvalidDomain: () => void;
  /** The currently 'active' site/domain. */
  defaultSite: string;
  invalidDomain: string;
  sessionConfig?: SessionConfigResult['data'];
  userInfo?: GetUserMeFullItem; // get /user/me/
  userGroups?: UserAccountGroups;
  apiUrl?: string; // derived from envState
  authToken?: string; // derived from auth
  authInfo?: User; // re-exported for convenience via useAuth?.user
  userIsAdmin?: boolean;
  userPermissions?: { [key: string]: any } | null;
}

// Initial state - certain other values in context are derived.
const baseCosmosState: CosmosStateType = {
  renderReady: false, // prevent no permissions flicker
  initStatus: undefined, // Track init status for effect
  defaultSite: '',
  sessionConfig: undefined,
  userInfo: undefined,
  userGroups: undefined,
};

interface CosmosStateType {
  renderReady: boolean;
  defaultSite: string;
  initStatus: 'success' | 'invalid' | 'failed' | undefined; // Track init status from effect
  sessionConfig?: SessionConfigResult['data'];
  userInfo?: GetUserMeFullItem;
  userGroups?: UserAccountGroups;
}

// Custom return type for the initialSessionConfig useQuery.
// Status:
// - success: the domain we tried was valid and we got the data
// - invalid: the domain we tried was invalid and you need to fallback to oidc site
// - failed: the oidc fallback site failed to initialize the app, so cosmos is unable to init
type InitialStateUpdate = {
  initStatus: 'success' | 'invalid' | 'failed'; // tried domain status for effect
  badSite: string; // if bad status return the site that failed/invalid for invalidDomain storage
  defaultSite: string; // empty string until success
  sessionConfig?: SessionConfigResult['data']; // undefined until success
};

interface InitCosmosQueryFnParams {
  domain: string;
  isFallbackDomain: boolean;
  apiUrl: CosmosCreateProvidersParams['apiUrl'];
  authToken: CosmosCreateProvidersParams['authToken'];
  stringifyOptions: CosmosCreateProvidersParams['stringifyOptions'];
  queryClient: CosmosCreateProvidersParams['queryClient'];
  setInvalidDomain: ReturnType<typeof useSessionStorage<string>>[1];
}

//----------------------------------------------------------------------
// Helper Functions
//----------------------------------------------------------------------
function getAuthTokenStr(auth: AuthContextProps) {
  const tokenType = String(auth?.user?.token_type);
  const accessToken = auth?.user?.access_token;
  return `${capitalizeFirstLetter(tokenType)} ${accessToken}`;
}

function getApiUrl(envState?: EnvData) {
  return `https://${envState?.API_SERVER_URL}/rest/admin`;
}

function getDefaultOidcSite(auth: AuthContextProps) {
  return String(auth?.user?.profile['bloxcms.default_site'])?.toLowerCase()?.trim();
}

function getAddressBarDomain(pathname: string) {
  return pathname?.split('/')[1]?.toLowerCase()?.trim();
}

function checkCosmosAccess(
  sessionConfig?: SessionConfigResult['data'],
  userInfo?: GetUserMeFullItem,
) {
  if (!sessionConfig || !userInfo) {
    return false; // No session or user info, can't determine access yet.
  }
  const hasCosmos = Boolean(sessionConfig?.software_privileges?.core?.includes('cosmos'));
  const isStaffUser = Boolean(userInfo?.account_type === 'staff');
  // Staff always allowed, otherwise must have cosmos privileges.
  return isStaffUser || hasCosmos;
}

//----------------------------------------------------------------------
// Query Functions
//----------------------------------------------------------------------
const tryGetInitialSessionConfig = async ({
  apiUrl,
  authToken,
  defaultSite,
  stringifyOptions,
  queryClient,
}: CosmosCreateProvidersParams) => {
  if (!apiUrl || !authToken || !defaultSite || !stringifyOptions || !queryClient) {
    throw new Error('[tryGetInitialSessionConfig] Missing required parameters!');
  }
  try {
    const { getSessionConfig } = createSessionsProvider({
      apiUrl,
      authToken,
      defaultSite,
      stringifyOptions,
      queryClient,
    });
    const sessionConfigResult = await getSessionConfig();
    return sessionConfigResult;
  } catch (error) {
    console.error('[tryGetInitialSessionConfig] error:', error);
    throw error;
  }
};

// Intialize Cosmos - get /core/session/config/
const initCosmosQueryFn = async ({
  domain,
  isFallbackDomain,
  apiUrl,
  authToken,
  stringifyOptions,
  queryClient,
}: InitCosmosQueryFnParams): Promise<InitialStateUpdate> => {
  try {
    // Try using the domain that was passed to the function
    const sessionConfigResult = await tryGetInitialSessionConfig({
      apiUrl,
      authToken,
      defaultSite: domain,
      stringifyOptions,
      queryClient,
    });
    // If successful the domain we tried was valid.
    const successState: InitialStateUpdate = {
      initStatus: 'success',
      sessionConfig: sessionConfigResult?.data,
      defaultSite: domain,
      badSite: '',
    };
    return successState;
  } catch (error) {
    // If the fetch was unsuccesful return an invalid state update
    // additionally, if flag for "isFallbackSite" was true, set this a 'failure' status
    const failedState: InitialStateUpdate = {
      initStatus: isFallbackDomain ? 'failed' : 'invalid',
      badSite: domain,
      defaultSite: '',
      sessionConfig: undefined,
    };
    return failedState;
  }
};

//----------------------------------------------------------------------
// CosmosContext Provider and Hooks
//----------------------------------------------------------------------
export const CosmosContext = createContext<CosmosContextType | undefined>(undefined);

export const useCosmos = () => {
  const context = useContext(CosmosContext);
  if (!context) {
    throw new Error('useCosmos must be used within a CosmosProvider!');
  }
  return context;
};

export const CosmosProvider = ({ children }: PropsWithChildren) => {
  // hooks and states
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();
  const { envState } = useCosmosAuthEnv();
  const [cosmosState, setCosmosState] = useState<CosmosStateType>(baseCosmosState);

  // session storage for invalid domain as app must nav to reconfigure if it was invalid
  const [invalidDomain, setInvalidDomain] = useSessionStorage<string>({
    key: 'invalidDomain',
    defaultValue: '',
  });

  // derive consts
  const addressBarDomain = getAddressBarDomain(location.pathname);
  const authBloxCMSDefaultSite = getDefaultOidcSite(auth);
  const apiUrl = getApiUrl(envState);
  const authToken = getAuthTokenStr(auth);

  // check access
  const canAccessCosmos = useMemo(() => {
    return checkCosmosAccess(cosmosState?.sessionConfig, cosmosState?.userInfo);
  }, [cosmosState?.sessionConfig, cosmosState?.userInfo]);

  // Mutation - Swap to the domain string provided.
  const { mutateAsync: changeActiveSiteAsync, status: mutateSiteStatus } = useMutation({
    retry: false,
    mutationFn: async (newDefaultSite: string) => {
      // On swapped site - Clear/reset/invalidate any errors,
      // queries, states as we are about to refetch / reinitialize.

      // Wait to make this a real async fn and allow states to reconfig
      // basically allows this fn to go into "loading" state for other checks...
      await new Promise((resolve) => setTimeout(resolve, 300));

      // Navigating to a new site should re-trigger the initCosmosQuery
      // and re-initialize the cosmos state with the new site data.
      navigate(`/${newDefaultSite?.toLowerCase()}`, {
        replace: false,
        state: {
          fromSite: addressBarDomain?.toLowerCase(),
          newSite: newDefaultSite?.toLowerCase(),
        },
      });

      return newDefaultSite;
    },
    // onMutate docs: function will fire before the mutation function is fired
    // and is passed the same variables the mutation function would receive
    onMutate: async (variables: string) => {
      // Clear all queryClient cache
      queryClient.clear();

      // Stop all queries
      await queryClient.cancelQueries(
        {
          active: true,
          stale: true,
          fetching: true,
          inactive: true,
        },
        {
          revert: false,
          silent: true,
        },
      );

      // Invalidate all, disable all refetch
      await queryClient.invalidateQueries(
        {
          active: true,
          stale: true,
          fetching: true,
          inactive: true,
          refetchActive: false,
          refetchInactive: false,
        },
        {
          throwOnError: false,
          cancelRefetch: true,
        },
      );

      // Reset all to their initial state
      await queryClient.resetQueries(
        {
          active: true,
          stale: true,
          fetching: true,
          inactive: true,
        },
        {
          throwOnError: false,
          cancelRefetch: true,
        },
      );

      // Clear any invalid domain, init will set if invalid.
      setInvalidDomain('');

      // Reset the cosmos state to initial state
      flushSync(() => {
        setCosmosState(baseCosmosState);
      });

      return variables;
    },
    onError: (error, variables, context) => {
      // Can't really error as the mutations just a navigate call, logging anyway for posterity.
      console.error(`[CosmosContext] - Change Site Mutation Error...`);
    },
  });

  // initCosmosQuery - fetches and initializes state once auth is valid
  const initQueryEnabled = Boolean(
    auth?.isAuthenticated &&
      !auth.isLoading &&
      apiUrl &&
      authToken &&
      queryClient &&
      stringifyOptions &&
      mutateSiteStatus !== 'loading', // Edge case dont run while swapping sites... give time for states to update...
  );

  // Determine the domain we're trying for the initCosmosQuery...
  const usingDomain = addressBarDomain || authBloxCMSDefaultSite;

  const {
    data: initCosmosQueryData,
    error: initCosmosQueryError,
    status: initCosmosQueryStatus,
  } = useQuery<InitialStateUpdate, ApiErrorResponse>({
    queryKey: ['CosmosProvider', 'initCosmosQuery', 'getSessionConfig', usingDomain],
    queryFn: async () => {
      const result = await initCosmosQueryFn({
        domain: usingDomain,
        isFallbackDomain: Boolean(usingDomain === authBloxCMSDefaultSite),
        apiUrl: apiUrl,
        authToken: authToken,
        stringifyOptions: stringifyOptions,
        queryClient: queryClient,
        setInvalidDomain: setInvalidDomain,
      });
      return result;
    },
    enabled: initQueryEnabled,
    staleTime: Infinity,
    cacheTime: Infinity,
    retry: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  // Sync to state initCosmosQuery once it's available or changes.
  useEffect(() => {
    if (!initCosmosQueryData) return;

    const initStatus = initCosmosQueryData?.initStatus;
    const defaultSite = initCosmosQueryData?.defaultSite;
    const sessionConfig = initCosmosQueryData?.sessionConfig;
    const badSite = initCosmosQueryData?.badSite;

    if (initStatus === 'success') {
      // Update the cosmos state as fetch was valid
      // Update the state with the the new/initial data fetched and processed.
      setCosmosState((prevValues) => ({
        ...prevValues,
        initStatus: initStatus,
        defaultSite: defaultSite,
        sessionConfig: sessionConfig,
      }));
      // usingDomain could have fallbacked to use the OIDC (e.g. empty string in addressBarDomain)
      // so if the determined defaultSite !== addressBarDomain, call navigate to site we used to init
      // as this point would have been used as the queryKey for the initCosmosQuery, avoiding refetch
      if (addressBarDomain !== defaultSite) {
        navigate(`/${defaultSite}`, {
          replace: true,
          state: {
            fromSite: addressBarDomain,
            newSite: defaultSite,
          },
        });
      }
    }

    if (initStatus === 'invalid') {
      // Update the cosmos state - invalid means we need to try again
      // using the oidc fallback site... likely the user typed in a domain that
      // was invalid so we need to re-run the initCosmosQuery with the oidc site
      // call navigate with the oidc site to change queryKey and refetch
      setCosmosState((prevValues) => ({
        ...prevValues,
        initStatus: initStatus,
        defaultSite: '',
        sessionConfig: undefined,
      }));
      setInvalidDomain(badSite); // Store the bad site for error display
      navigate(`/${authBloxCMSDefaultSite}`, {
        replace: true,
        state: {
          fromSite: badSite?.toLowerCase(),
          newSite: authBloxCMSDefaultSite,
        },
      });
    }

    if (initStatus === 'failed') {
      // this means even the fallback oidc site has failed
      // and cosmos cant proceed with initialization at all
      console.error('[CosmosProvider] - Failed to initialize domain.');
      setCosmosState((prevValues) => ({
        ...prevValues,
        initStatus: initStatus,
        defaultSite: '',
        sessionConfig: undefined,
      }));
      setInvalidDomain(badSite);
    }
  }, [
    initCosmosQueryData,
    addressBarDomain,
    setInvalidDomain,
    authBloxCMSDefaultSite,
    navigate,
  ]);

  // Enable post-init queries once the defaultSite is set and initStatus is success
  const enablePostInitQueries = Boolean(
    cosmosState?.defaultSite && cosmosState?.initStatus === 'success',
  );

  // Get current user info from /user/me/
  const {
    data: userMeQueryData,
    error: userMeQueryError,
    status: userMeQueryStatus,
  } = useQuery<GetUserMeFullItem, ApiErrorResponse>({
    queryKey: ['CosmosProvider', 'getUserMe', cosmosState?.defaultSite],
    queryFn: async () => {
      if (!cosmosState?.defaultSite) {
        throw new Error('[CosmosContext - getUserMe] defaultSite is missing!');
      }
      const { getUserMe } = createUsersProvider({
        apiUrl: apiUrl,
        authToken: authToken,
        defaultSite: cosmosState?.defaultSite,
        stringifyOptions: stringifyOptions,
        queryClient: queryClient,
      });
      const userInfo = await getUserMe();
      const userData: GetUserMeFullItem = {
        ...userInfo?.data,
      };
      return userData;
    },
    enabled: enablePostInitQueries,
    staleTime: Infinity,
    cacheTime: Infinity,
    retry: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  // Sync to state userMeData once it's available or changes.
  useEffect(() => {
    if (userMeQueryData) {
      setCosmosState((prevValues) => ({
        ...prevValues,
        userInfo: userMeQueryData,
      }));
    }
  }, [userMeQueryData]);

  // Get current user groups from /user/me/groups
  const {
    data: userGroupsQueryData,
    error: userGroupsQueryError,
    status: userGroupsQueryStatus,
  } = useQuery<UserAccountGroups, ApiErrorResponse>({
    queryKey: ['CosmosProvider', 'getActiveUserGroups', cosmosState?.defaultSite],
    queryFn: async () => {
      if (!cosmosState?.defaultSite) {
        throw new Error('[CosmosContext - getActiveUserGroups] defaultSite is missing!');
      }
      const { getActiveUserGroups } = createUsersProvider({
        apiUrl: apiUrl,
        authToken: authToken,
        defaultSite: cosmosState?.defaultSite,
        stringifyOptions: stringifyOptions,
        queryClient: queryClient,
      });
      const userGroups = await getActiveUserGroups();
      return userGroups;
    },
    enabled: enablePostInitQueries,
    staleTime: Infinity,
    cacheTime: Infinity,
    retry: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  // Sync to state userGroupsQueryData once it's available or changes.
  useEffect(() => {
    if (userGroupsQueryData) {
      setCosmosState((prevValues) => ({
        ...prevValues,
        userGroups: userGroupsQueryData,
      }));
    }
  }, [userGroupsQueryData]);

  // Context value
  const contextValue = useMemo(() => {
    return {
      defaultSite: cosmosState?.defaultSite, // Note: defaultSite is the 'active' domain in the address bar.
      sessionConfig: cosmosState?.sessionConfig,
      userInfo: cosmosState?.userInfo,
      userGroups: cosmosState?.userGroups,
      userIsAdmin: cosmosState?.sessionConfig?.user?.site_admin || false,
      userPermissions: cosmosState?.sessionConfig?.user?.permissions || null,
      apiUrl: apiUrl,
      authToken: authToken,
      authInfo: auth?.user || undefined,
      changeActiveSiteAsync: changeActiveSiteAsync,
      invalidDomain: invalidDomain,
      clearInvalidDomain: () => {
        setInvalidDomain('');
      },
    };
  }, [
    cosmosState,
    auth,
    apiUrl,
    authToken,
    changeActiveSiteAsync,
    invalidDomain,
    setInvalidDomain,
  ]);

  // --------------- Rendering / Returns ---------------
  // Check if all queries are successful and set cosmosState.ready to render
  // as to prevent no permissions flicker while loading/checking.
  useEffect(() => {
    const allReady = Boolean(
      initCosmosQueryStatus === 'success' &&
        userMeQueryStatus === 'success' &&
        userGroupsQueryStatus === 'success' &&
        mutateSiteStatus !== 'loading',
    );
    // All queries are done and ready to render
    if (allReady && !cosmosState.renderReady) {
      setCosmosState((prevValues) => ({
        ...prevValues,
        renderReady: true,
      }));
    }
    // Unready if these were ready and they became not ready
    if (!allReady && cosmosState.renderReady) {
      setCosmosState((prevValues) => ({
        ...prevValues,
        renderReady: false,
      }));
    }
  }, [
    cosmosState,
    initCosmosQueryStatus,
    mutateSiteStatus,
    userMeQueryStatus,
    userGroupsQueryStatus,
  ]);

  const anyErrors = Boolean(
    initCosmosQueryError ||
      userMeQueryError ||
      userGroupsQueryError ||
      cosmosState.initStatus === 'failed',
  );

  if (anyErrors) {
    const errorResults: ErrorDetails[] = [
      initCosmosQueryError,
      userMeQueryError,
      userGroupsQueryError,
    ]
      .filter(Boolean)
      .map((error) => {
        return {
          message: error?.message,
          status: error?.status,
          error: error,
        };
      });

    // If we had a total failure to initialize via any domain push extra error...
    if (cosmosState.initStatus === 'failed') {
      errorResults.push({
        message: 'Application failed to initialize.',
        status: 'Please try clearing the browser cache and try again.',
        error: '',
      });
    }

    console.error('[CosmosProvider Errors]:', errorResults);

    return (
      <ErrorAlerts
        severity="error"
        buttonText="Reload"
        errors={errorResults}
        buttonOnClick={() => {
          // Best to try a hard reset if things fail this early.
          window.sessionStorage.clear();
          window?.location?.reload();
        }}
      />
    );
  }

  if (!cosmosState.renderReady || mutateSiteStatus === 'loading') {
    return <BloxLoadingSplash message="Please wait..." />;
  }

  if (!canAccessCosmos) {
    return <NoCosmosPermissions />;
  }

  // You can see this flash when we hit the back/forward button after changing sites
  // beacuse we need config fetched first in order to determine the the "route"
  // for cosmos to load, but the app was already mounted with defaultSite/<routes>/
  // so this fallback should show a loader for brief the duration of a site switch
  // and if it takes too long, it will log a warning for us to investigate.
  if (cosmosState?.defaultSite && addressBarDomain !== cosmosState?.defaultSite) {
    return (
      <RouteWithTimeoutWarning
        defaultSite={cosmosState?.defaultSite}
        addressBarDomain={addressBarDomain}
        oidcDefaultSite={authBloxCMSDefaultSite}
      />
    );
  }

  return <CosmosContext.Provider value={contextValue}>{children}</CosmosContext.Provider>;
};

interface RouteWithTimeoutWarningProps {
  defaultSite: string;
  addressBarDomain: string;
  oidcDefaultSite: string;
}

const RouteWithTimeoutWarning = memo(
  ({ defaultSite, addressBarDomain, oidcDefaultSite }: RouteWithTimeoutWarningProps) => {
    const [isTimedOut, setIsTimedOut] = useState(false);

    // If nothing matches for 30 seconds, log and timeout.
    useEffect(() => {
      if (isTimedOut) return;
      const timeout = setTimeout(() => {
        setIsTimedOut(true);
        console.error(
          '[RouteWithTimeoutWarning] No route was matched for the greater than 10 seconds!\n',
          'Logging information for investigation:',
          '\ndefaultSite:',
          defaultSite,
          '\naddressBarDomain:',
          addressBarDomain,
          '\noidcDefaultSite:',
          oidcDefaultSite,
        );
      }, 30000);

      return () => {
        clearTimeout(timeout); // Cleanup timeout on unmount
      };
    }, [isTimedOut, setIsTimedOut, defaultSite, addressBarDomain, oidcDefaultSite]);

    if (isTimedOut) {
      return (
        <ErrorAlerts
          headerText="Error"
          severity="error"
          showSeverity={false}
          showDetails={false}
          buttonText="Reload"
          buttonOnClick={() => {
            // Best to try a hard reset to root if things fail.
            window.sessionStorage.clear();
            window?.location?.replace(`${window.location.origin}/`);
          }}
          errors={[
            {
              status: `Sorry, we were unable to load the application.`,
              message: `Please try clearing your browser cache and reloading the page.`,
            },
          ]}
        />
      );
    }

    // Show the loading splash while we wait for the site swithch to complate
    // and give react-router a path to render in the meantime fixing the
    // "no route matched" warning from react-router mid back/forward navigation
    // https://github.com/remix-run/react-router/discussions/10851
    return (
      <Routes>
        <Route
          path="*"
          element={<BloxLoadingSplash message="Loading sites..." />}
        />
      </Routes>
    );
  },
);

RouteWithTimeoutWarning.displayName = 'LoadingWithTimeout';
