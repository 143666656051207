import { assertResponseSuccess } from 'providers/functions';
import { CosmosCreateProvidersParams, httpClient } from '../CosmosRAProvidersContext';

import { GetWorkflowByIdResult, GetWorkflowsResult } from 'utility/types/dataProvider';

// -----------------------------------------------
// ------------ WORKFLOWS PROVIDER ----------------
// -----------------------------------------------
export const createWorkflowsProvider = ({
  apiUrl,
  authToken,
  defaultSite,
  stringifyOptions,
  queryClient,
}: CosmosCreateProvidersParams) => ({
  // Get all site workflows
  getWorkflows: async (): Promise<GetWorkflowsResult> => {
    try {
      const url = `${apiUrl}/core/workflow/`;

      const { json, status } = await httpClient({
        url: url,
        options: {
          method: 'GET',
        },
        authToken: authToken,
        defaultSite: defaultSite,
      });

      await assertResponseSuccess(json, status);

      const result: GetWorkflowsResult = {
        status: json?.status || status,
        data: json?.data,
        ...json,
      };

      return result;
    } catch (error) {
      console.error('[dataProvider] getWorkflows() error:' + error?.toString());
      throw error;
    }
  },

  // Retrieve a workflow
  getWorkflowById: async (workflowId: string): Promise<GetWorkflowByIdResult> => {
    try {
      const url = `${apiUrl}/core/workflow/${workflowId}/`;

      const { json, status } = await httpClient({
        url: url,
        options: {
          method: 'GET',
        },
        authToken: authToken,
        defaultSite: defaultSite,
      });

      await assertResponseSuccess(json, status);

      const result: GetWorkflowByIdResult = {
        status: json?.status || status,
        data: json?.data,
        ...json,
      };

      return result;
    } catch (error) {
      console.error('[dataProvider] getWorkflowById() error:' + error?.toString());
      throw error;
    }
  },
});
