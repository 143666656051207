import { createSvgIcon, SvgIconProps } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { forwardRef, memo } from 'react';
import { bloxColors } from '../themes/constants';

// BLOX NXT Logo
// Automatically changes the color of the logo based on the theme
export const BloxNXTLogo = memo(
  forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
    const theme = useTheme();
    const isDarkMode = theme.palette.mode === 'dark';
    const textColor = isDarkMode ? bloxColors.white : bloxColors.black;

    const Icon = createSvgIcon(
      <svg
        xmlns="http://www.w3.org/2000/svg"
        id="BloxNXTLogo"
        data-name="BloxNXTLogo"
        data-testid="BloxNXTLogo"
        viewBox="0 0 1546.08 253.04"
      >
        <defs>
          <style>{`.cls-blue{fill:${bloxColors.logoblue}}.cls-text{fill:${textColor}}`}</style>
        </defs>
        <g>
          <g>
            <polygon
              className="cls-blue"
              points="182.96 220.46 126.17 253.04 126.17 187.67 182.96 220.46"
            />
            <polygon
              className="cls-blue"
              points="201.78 122.08 126.17 165.74 126.17 138.25 225.26 81.41 225.26 196.2 201.78 209.67 201.78 122.08"
            />
          </g>
          <g>
            <polygon
              className="cls-blue"
              points="154.79 24.5 211.4 57.4 154.79 90.08 154.79 24.5"
            />
            <polygon
              className="cls-blue"
              points="60.18 57.4 135.79 101.05 111.99 114.79 13.22 57.39 112.62 0 136.03 13.6 60.18 57.4"
            />
          </g>
          <g>
            <polygon
              className="cls-blue"
              points="56.79 113.61 0 81.04 0 146.4 56.79 113.61"
            />
            <polygon
              className="cls-blue"
              points="75.61 211.99 0 168.34 0 195.82 99.1 252.66 99.1 137.87 75.61 124.4 75.61 211.99"
            />
          </g>
        </g>
        <g>
          <path
            className="cls-text"
            d="M281.53,225.75V28.03h91.52c13.74,0,25.32,2.31,34.74,6.92,9.41,4.62,16.57,10.78,21.47,18.5,4.89,7.72,7.34,16.57,7.34,26.55,0,8.66-1.6,16.01-4.8,22.03-3.2,6.03-7.58,10.78-13.13,14.27-5.56,3.48-11.91,5.98-19.07,7.48v1.98c8.66,1.13,16.1,3.53,22.31,7.2,6.21,3.67,11.02,8.8,14.41,15.39,3.39,6.59,5.08,14.69,5.08,24.29,0,10.17-2.54,19.3-7.63,27.4-5.08,8.1-12.48,14.41-22.17,18.93-9.7,4.52-21.32,6.78-34.88,6.78h-95.19ZM398.19,85.09c0-8.28-2.68-14.55-8.05-18.78-5.37-4.24-12.76-6.36-22.17-6.36h-48.3v49.99h48.3c9.41,0,16.81-2.12,22.17-6.36,5.37-4.24,8.05-10.4,8.05-18.5ZM319.66,193.83h51.97c9.98,0,17.7-2.16,23.16-6.5,5.46-4.33,8.19-10.83,8.19-19.49s-2.97-15.77-8.9-20.2c-5.93-4.42-13.8-6.64-23.58-6.64h-50.84v52.82Z"
          />
          <path
            className="cls-text"
            d="M502.52,28.03v165.8h88.13v31.92h-126.26V28.03h38.13Z"
          />
          <path
            className="cls-text"
            d="M684.48,227.73c-14.12,0-27.31-2.44-39.54-7.34-12.24-4.89-22.93-11.81-32.06-20.76-9.14-8.94-16.29-19.58-21.47-31.92-5.18-12.33-7.77-25.94-7.77-40.81s2.59-28.2,7.77-40.53c5.18-12.33,12.33-23.02,21.47-32.06,9.13-9.04,19.82-16,32.06-20.9,12.24-4.89,25.42-7.34,39.54-7.34s27.02,2.45,39.26,7.34c12.24,4.9,22.92,11.86,32.06,20.9,9.13,9.04,16.29,19.73,21.47,32.06,5.18,12.34,7.77,25.84,7.77,40.53s-2.59,28.48-7.77,40.81c-5.18,12.34-12.38,22.98-21.61,31.92-9.23,8.95-19.91,15.87-32.06,20.76-12.15,4.9-25.19,7.34-39.12,7.34ZM684.48,194.96c12.05,0,22.69-2.83,31.92-8.47,9.22-5.65,16.52-13.6,21.89-23.87,5.37-10.26,8.05-22.17,8.05-35.73s-2.68-25.75-8.05-36.01c-5.37-10.26-12.67-18.17-21.89-23.73-9.23-5.55-19.87-8.33-31.92-8.33s-23.02,2.78-32.34,8.33c-9.32,5.56-16.62,13.47-21.89,23.73-5.27,10.27-7.91,22.27-7.91,36.01s2.64,25.47,7.91,35.73c5.27,10.27,12.57,18.22,21.89,23.87,9.32,5.65,20.1,8.47,32.34,8.47Z"
          />
          <path
            className="cls-text"
            d="M781.58,28.03h43.5l50.84,69.48,49.71-69.48h43.5l-70.9,98.86,70.9,98.86h-43.5l-49.71-69.49-50.84,69.49h-43.5l71.74-98.86-71.74-98.86Z"
          />
        </g>
        <path
          className="cls-text"
          d="M1182.57,28.41v198.48h-28.92l-98.96-153.68v153.68h-28.35V28.41h28.92l98.96,153.11V28.41h28.36Z"
        />
        <path
          className="cls-text"
          d="M1393.82,28.41h152.26v23.82h-61.81v174.66h-28.36V52.23h-62.1v-23.82Z"
        />
        <polygon
          className="cls-text"
          points="1314.27 113.4 1375.95 28.41 1344.48 28.41 1297.56 92.06 1314.27 113.4"
        />
        <polygon
          className="cls-blue"
          points="1196.47 28.41 1229.87 28.41 1304.06 127.65 1230.01 226.89 1196.47 226.89 1268.99 127.65 1196.47 28.41"
        />
        <polygon
          className="cls-text"
          points="1314.27 141.9 1375.95 226.89 1344.48 226.89 1297.56 163.23 1314.27 141.9"
        />
      </svg>,
      'BloxNXTLogo',
    );

    return (
      <Icon
        {...props}
        ref={ref}
      />
    );
  }),
);
BloxNXTLogo.displayName = 'BloxNXTLogo';
