import KeyIcon from '@mui/icons-material/Key';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import { Button, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import { User, UserManager, WebStorageStateStore } from 'oidc-client-ts';
import {
  createContext,
  memo,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { AuthProvider, useAuth } from 'react-oidc-context';
import { useQuery, useQueryClient } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { bloxValues } from 'themes/constants';
import { emdash } from 'utility/constants';
import { clearPrefixedStorage, isDevMode, isUseEnvVars } from 'utility/functions';
import useBroadcastChannel from 'utility/hooks/useBroadcastChannel';
import { getSolidThemeGray } from 'utility/styles';
import { ApiErrorResponse } from 'utility/types/dataProvider';
import BloxLoadingSplash from '../../components/generic/BloxLoadingSplash';
import ErrorAlerts from '../../components/generic/ErrorAlerts';

/**
 * FILE NOTES:
 * Logging can be enabled in the index.tsx file for oidc-client-ts for easier debugging.
 * import { Log } from 'oidc-client-ts';
 * Log.setLogger(console);
 * Log.setLevel(Log.DEBUG);
 *
 *
 * Related Tickets / Docs / Known Issues:
 * https://townnews.atlassian.net/browse/CMSADMIN-986
 * https://authts.github.io/oidc-client-ts/classes/UserManagerEvents.html
 * https://authts.github.io/oidc-client-ts/classes/UserManager.html
 * https://authts.github.io/oidc-client-ts/classes/UserManagerSettingsStore.html
 * https://github.com/authts/react-oidc-context#:~:text=is%20still%20there%2C-,signinSilent,-%2D%20which%20handles%20renewing
 * https://github.com/authts/react-oidc-context?tab=readme-ov-file#adding-event-listeners
 * https://github.com/authts/react-oidc-context/issues/1324 - withAuthReq wrapper redirect
 * https://github.com/authts/react-oidc-context/issues/1253 - silent renew errors
 * https://github.com/authts/react-oidc-context/issues/390 - silent renew duplication
 * https://github.com/authts/oidc-client-ts/issues/644 - refresh token renew
 * https://github.com/authts/react-oidc-context/issues/1081 - other tabs logout
 * https://github.com/authts/oidc-client-ts/issues/543 - detecting events
 *
 * The react-oidc-context library provides a withAuthenticationRequired
 * higher-order component to protect routes. However in some testing
 * it seems to have some issues with the states sometimes, showing a
 * loader flash when it shouldn't, not cleaning stale auth states
 * and some token issues we have been addressing in CMSADMIN-986.
 *
 * However it is a good idea to look at the source code of what it
 * does and how it determines to show the different loaders and states,
 * which we are mostly doing the same in WithAuthChecks wrapper.
 *
 * https://github.com/authts/react-oidc-context/blob/main/src/withAuthenticationRequired.tsx
 *
 * Note: looks like they copied most of auth0-react's withAuthenticationRequired
 * https://github.com/auth0/auth0-react/blob/main/src/with-authentication-required.tsx
 *
 *
 */

export interface EnvData {
  ENV?: string | undefined;
  API_SERVER_URL?: string | undefined;
  OIDC_SERVER_URL?: string | undefined;
  [key: string]: any;
}

interface CustomUserStateProperties {
  restore_route?: string;
}

// Helper functions for OIDC setup
export function buildEndSessionUrl(oidcServerUrl?: string) {
  if (!oidcServerUrl) {
    console.log('[buildEndSessionUrl] Missing OIDC_SERVER_URL!');
    return '';
  }
  return `${oidcServerUrl}api/-/logout`;
}

export function getOidcClientId() {
  return 'cosmos-admin';
}

export function validateEnvVars(envVars: Partial<EnvData>) {
  if (!envVars?.API_SERVER_URL || !envVars?.OIDC_SERVER_URL || !envVars?.ENV) {
    throw new Error(`Environment variables missing required data.`);
  }
}

export const SetupAuthentication = memo(({ children }: PropsWithChildren) => {
  const navigate = useNavigate();
  const devMode = isDevMode(); // process.env.NODE_ENV
  const useEnvVars = isUseEnvVars(); // process.env.REACT_APP_USE_ENV_VARS
  const useLocalEnvVars = useEnvVars || devMode;
  const [envState, setEnvState] = useState<EnvData>();

  const { data: getEnvData, error: getEnvError } = useQuery<EnvData, ApiErrorResponse>({
    queryKey: ['AuthEnv', 'getEnvData', `localenv:${useLocalEnvVars}`],
    queryFn: async () => {
      // Fetches environment variables via the <link> tags href in index.html
      // or the process.env if running locally in development mode or prefer use env vars.
      if (useLocalEnvVars) {
        const envVars = {
          ENV: process.env.REACT_APP_ENV,
          API_SERVER_URL: process.env.REACT_APP_API_SERVER_URL,
          OIDC_SERVER_URL: process.env.REACT_APP_OIDC_SERVER_URL,
        };
        validateEnvVars(envVars);
        return envVars;
      } else {
        const envHref = window.document.getElementById('env-id')?.getAttribute('href');

        if (!envHref) {
          throw new Error("Element with 'env-id' not found.");
        }

        try {
          const envUrl = new URL(envHref, window.location.origin).toString();
          const response = await fetch(envUrl);

          if (!response.ok) {
            throw new Error(`Env not ok: ${response.status} ${response.statusText}`);
          }

          const result: EnvData = await response.json();
          const envVars = {
            ENV: result.ENV,
            API_SERVER_URL: result.API_SERVER_URL,
            OIDC_SERVER_URL: result.OIDC_SERVER_URL,
            ...result,
          };

          validateEnvVars(envVars);
          return envVars;
        } catch (error) {
          throw error;
        }
      }
    },
    enabled: true,
    staleTime: Infinity,
    cacheTime: Infinity,
    retry: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    keepPreviousData: true,
  });

  // Sync to state when fetched or changes.
  useEffect(() => {
    if (getEnvData) {
      setEnvState(getEnvData);
    }
  }, [getEnvData]);

  // UserManager Settings instance for the AuthProvider Context
  const userManagerMemo = useMemo(() => {
    if (!envState?.OIDC_SERVER_URL) {
      return undefined;
    }

    const userManager = new UserManager({
      // The docs say either handle the silent manually in a useEffect or here, but do not do both.
      // If you set both, you will get double invocations of renew and the timing could
      // potentially cause the auth server to potentially reject the second refresh token.
      // This may lead to various api errors and logouts.
      // We will manually control the start/stop of silent renew in the WithAuthEnvChecksProvider.
      automaticSilentRenew: false,
      includeIdTokenInSilentRenew: true,
      includeIdTokenInSilentSignout: true,
      accessTokenExpiringNotificationTimeInSeconds: 60, // Notify event handler 1m before expiry
      // Note: default prefix in storages is "oidc." and it looks like the full user key is
      // "oidc.user:{OIDC_SERVER_URL}:{CLIENT_ID}" e.g. "oidc.user:https://admin.us-corp-dev-3.vip.tndev.net/:cosmos-admin"
      // Note signInPopup did not seem to work if statestore was using session storage
      // my guess is that is because session storage is not shared across windows, so the popup could not communicate back.
      stateStore: new WebStorageStateStore({ store: window.localStorage }), // Must be LS for signInPopups to work.
      // With the user in session storage, we shouldnt have issues with the refresh tokens being reused cross-tab or causing race conditions.
      userStore: new WebStorageStateStore({ store: window.sessionStorage }), // Each tab has its own session storage for user/jwt.
      filterProtocolClaims: false,
      authority: envState.OIDC_SERVER_URL,
      client_id: getOidcClientId(),
      redirect_uri: window.location.origin,
      post_logout_redirect_uri: window.location.origin,
      scope: 'openid email offline_access',
      refreshTokenAllowedScope: 'openid email offline_access',
      fetchRequestCredentials: 'include',
      response_type: 'code',
      response_mode: 'query',
      metadataSeed: {
        end_session_endpoint: buildEndSessionUrl(envState.OIDC_SERVER_URL), // TNCMS-554796 ?
      },
      // Monitors OP listening for events.
      // MonitorSession is required for some events to fire, also maybe for TNCMS-554796 ?
      monitorSession: true,
      monitorAnonymousSession: false,
      revokeTokensOnSignout: true, //TNCMS-554796 ?
    });

    return userManager;
  }, [envState]);

  // Show error component if fetching environment data fails.
  if (getEnvError) {
    console.log('[AuthProvider Error]:' + JSON.stringify(getEnvError, null, 2));
    return (
      <ErrorAlerts
        severity="error"
        buttonText="Reload"
        errors={[
          {
            message: 'Error fetching environment data.',
            status: getEnvError?.status,
            error: getEnvError,
          },
        ]}
        buttonOnClick={() => {
          // Best to try a hard reset if things fail this early.
          clearPrefixedStorage('oidc.', ['localStorage', 'sessionStorage']);
          window.sessionStorage.clear();
          window?.location?.reload();
        }}
      />
    );
  }

  const notReady =
    !envState ||
    !envState?.OIDC_SERVER_URL ||
    !envState?.API_SERVER_URL ||
    !envState?.ENV ||
    !userManagerMemo;

  if (notReady) {
    return <BloxLoadingSplash message="Building Authentication..." />;
  }

  /**
   * onSignInCallback
   * Here you can remove the code and state parameters from
   * the url when you are redirected from the authorize page.
   * as well as restore any routing state for the user.
   */
  const onSignInCallbackFn = async (user: User | void) => {
    if (!user) {
      console.warn('[AuthProvider] onSignInCallbackFn - Missing User in CB!');
    }

    // Pulling from the signinRedirectArgs state to redirect user back to where they were.
    const userState = user?.state as CustomUserStateProperties;
    // Default to root, e.g. cold start and clear code/query params from url to enable silent renew.
    // e.g restore: "/client-site.net/.../search?query=test" or fresh: "/"
    const navUserToRoute = userState?.restore_route || '/';
    navigate(navUserToRoute, { replace: true });
  };

  return (
    <AuthProvider
      userManager={userManagerMemo}
      onSigninCallback={onSignInCallbackFn}
    >
      <WithAuthEnvChecksProvider envState={envState}>
        {children}
      </WithAuthEnvChecksProvider>
    </AuthProvider>
  );
});
SetupAuthentication.displayName = 'SetupAuthentication';

type ReauthDialogState = { title: string; message: string } | null;

export const reauthDialogStateInitial: ReauthDialogState = null;

export const reauthDialogStateExpired: ReauthDialogState = {
  title: 'Session Expired',
  message: 'Your session has expired.',
};

export const reauthDialogStateLogout: ReauthDialogState = {
  title: 'Logged Out',
  message: 'You have been logged out.',
};

interface CosmosAuthEnvContextType {
  envState?: EnvData;
  /** internal mainly for use in auth setup, please use carefully */
  reauthDialog: ReauthDialogState;
  /** internal mainly for use in auth setup, please use carefully */
  setReauthDialog: React.Dispatch<SetStateAction<ReauthDialogState>>;
  /** internal mainly for use in auth setup, please use carefully */
  authLogin: () => Promise<void>;
  /** internal mainly for use in auth setup, please use carefully */
  authLogout: () => Promise<void>;
  /** internal mainly for use in auth setup, please use carefully */
  clearStaleStateDelayed: () => void;
}

export const CosmosAuthEnvContext = createContext<CosmosAuthEnvContextType | undefined>(
  undefined,
);

export const useCosmosAuthEnv = () => {
  const context = useContext(CosmosAuthEnvContext);
  if (!context) {
    throw new Error('useCosmosAuthEnv must be used within a WithAuthEnvChecksProvider!');
  }
  return context;
};

interface WithAuthChecksContextProps extends PropsWithChildren {
  envState?: EnvData;
}

type AuthBroadcastEventData = {
  // Type of event dispatched for us to determine which action to take.
  type: 'LOGOUT';
  // Which user id requested the logout, in case user is signed into multiple accounts in different tabs.
  // Will be taken from the JWT / auth.user.profile.sub
  userId: string | undefined;
};

// Auth roadcast events for cross-tab communication
const AUTH_BROADCAST_CHANNEL_NAME = 'AUTH_CHANNEL' as const;
const AUTH_BROADCAST_EVENTS = { LOGOUT: 'LOGOUT' } as const;

// Checks auth during session and runs silent renew, login, logout, broadcasts, etc...
// and sets up the useCosmosAuthEnv context for the app using useAuth hook.
export const WithAuthEnvChecksProvider = memo(
  ({ envState, children }: WithAuthChecksContextProps) => {
    const auth = useAuth();
    const location = useLocation();
    const queryClient = useQueryClient();
    const [initialized, setInitialized] = useState(false);

    // Reauth dialog state provided to the context
    // we tried to just rely on the auth hooks isAuthed at first in WithAuthChecks
    // as the type claimed "True while the user has a valid access token."
    // however it did not update when the users tokens expired. This seems to be
    // because the auth hooks isAuthed state is determined by user existence in storage,
    // which the library loads once into its own internal runtime memory, so even if the state
    // changes in storage, the hook does not update, and can cause desyncs with the actual auth state.
    // Calling auth.removeUser() fn will however remove the user from storage and update the hooks state isAuthed to false.
    // but keep in mind AuthN and AuthZ are separate concerns. A user may be AuthN
    // to the app but their tokens maybe expired and are no longer AuthZ to the api resources...
    const [reauthDialog, setReauthDialog] = useState<ReauthDialogState>(
      reauthDialogStateInitial,
    );

    const reauthDialogOpen = Boolean(reauthDialog);

    // For cross-tab communication we need to use a BroadcastChannel.
    const { post } = useBroadcastChannel<AuthBroadcastEventData, AuthBroadcastEventData>({
      name: AUTH_BROADCAST_CHANNEL_NAME,
      onMessage: async (event) => {
        const handleMessage = async () => {
          const payload = event.data;

          switch (payload.type) {
            case AUTH_BROADCAST_EVENTS.LOGOUT: {
              // If the reauth dialog is already showing we don't need to do anything as its blocking interactions.
              // This edge case is if you have multiple tabs open and you sign out in one tab,
              // sign back in on that same tab, and sign out again, the background tabs would recieve multiple broadcasts.
              // Since the first successful broadcast recevied for logout would have auth.removeUser()
              // then the second broadcast "currentUserId" would be undefined,
              // causing us to fall into the (!currentUserId || !initialized) if block due to no current user.
              if (reauthDialogOpen) {
                return;
              }

              // No dialog is showing so we need to perform some checks.
              // Get the current user's ID from the auth context and the incoming user's ID from the broadcast
              const currentUserId = auth.user?.profile?.sub;
              const incomingUserId = payload?.userId;

              // If the user is missing on this tab, or not initialized, pop the reauth dialog.
              // This should be very rare, but could have been they were mid loading or somehow not init yet.
              // e.g. the tab was backgrounded and deprioritized by chrome, so somehow it maybe didn't
              // recieve the broadcast to pop open the reauth dialog when the user logged out.
              if (!currentUserId || !initialized) {
                // deauth/remove user maybe here here for safety (should be empty, though)
                // don't clear shared (local) storages in case a logout is in progress on another tab
                await auth.removeUser();
                auth.clearStaleState(); // clear any stale states
                setReauthDialog(reauthDialogStateLogout); // show reauth dialog as user is now deauthed
                return;
              }

              // If we do have a user then ensure we only process the logout broadcast for the same user.
              const isCorrectUser = currentUserId === incomingUserId;
              if (!isCorrectUser) {
                return;
              }

              // If passed checks then stop silent renew, and show reauth dialog.
              // Manually re-enable the silent renew service wherever the user logs back in.
              auth.stopSilentRenew();
              await auth.removeUser(); // deauth - removes from storage and updates auth hooks state
              setReauthDialog(reauthDialogStateLogout);
              break;
            }

            default: {
              console.warn('[AuthBroadcastChannel] Unhandled payload:', payload);
              break;
            }
          }
        };

        // Call the handler on messages and log any errors
        try {
          await handleMessage();
        } catch (error) {
          console.error('[AuthBroadcastChannel] Error handling message:', error);
        }
      },
    });

    // The library should automatically clear stale states (default 900sec/15min)
    // in the storage after successful login, but in some edge cases it didn't.
    // Manually clearing it in some areas with delay to ensure its cleaned.
    const clearStaleStateDelayed = useCallback(() => {
      setTimeout(async () => {
        await auth.clearStaleState();
      }, 1000);
    }, [auth]);

    // login and logout moved up to centralize functions provided to CosmosRAProvidersContext authprovider
    const authLogin = useCallback(async () => {
      try {
        const redirUri = window.location.origin;
        const cosmosRoute = location.pathname + location.search;
        await auth.signinRedirect({
          redirect_uri: redirUri,
          state: { restore_route: cosmosRoute },
        });
        // Nothing after signinRedirect will even run, but anyway resolve promise type for react-admin.
        return Promise.resolve();
      } catch (error) {
        console.error('[authLogin] Error:', error);
        return Promise.reject(error);
      }
    }, [auth, location]);

    const authLogout = useCallback(async () => {
      try {
        // const redirUri = window.location.origin;
        // const cosmosRoute = location.pathname + location.search;
        // await auth.signoutRedirect({
        //   redirectMethod: 'replace',
        //   post_logout_redirect_uri: redirUri,
        //   redirectTarget: 'self',
        //   state: { restore_route: cosmosRoute },
        // });
        // ^^ Temp until TNCMS-554796 - use this when we have the official endpoint?
        // For now we manually kill the token via endpoint ourselves...

        const endSessionUrl = buildEndSessionUrl(envState?.OIDC_SERVER_URL);
        const response = await fetch(endSessionUrl, {
          method: 'POST',
          credentials: 'include',
        });

        if (!response.ok) {
          throw new Error(`[authLogout] response not ok: ${response}`);
        }
      } catch (error) {
        console.error('[authLogout] Error:', error);
      } finally {
        // Client-side cleanup should be done even if endpoint logout fails...

        // Cleanup react-query / oidc auth context / storages
        queryClient.clear();
        auth.stopSilentRenew();
        await auth.removeUser();
        await auth.clearStaleState();
        clearPrefixedStorage('oidc.', ['localStorage', 'sessionStorage']);

        // Broadcast hard logout event to other tabs for this user
        post({
          type: AUTH_BROADCAST_EVENTS.LOGOUT,
          userId: auth.user?.profile.sub,
        });

        // Since we can't really use signoutRedirect currently...
        // by this point we should have manually hit the logout endpoint
        // and all client side cleanup should be done, so we can just reload the page
        // and the WithAuthEnvChecksProvider should redirect to login when reloaded
        // as there should be no more user / AuthN in storage for this tab.
        // Alternatively we can route a user to localhost:3000/logout splash page
        window.location.reload(); // Hard reload will be removed with TNCMS-554796

        // Resolve for react-admin types (user is hard navigated away anyways)
        return Promise.resolve();
      }
    }, [auth, envState, queryClient, post]);

    // Listen for and respond to auth events.
    useEffect(() => {
      const checkAuthStateAsync = async () => {
        // Ensure auth context hook is ready
        if (!auth) {
          return;
        }

        // Just logging errors for now.
        if (auth.error) {
          console.error('[WithAuthEnvChecks] Auth error:', auth.error);
        }

        // Wait for auth to load before checking anything
        if (auth.isLoading) {
          return;
        }

        // Cold start without auth in storage - redirect to login
        if (!initialized && !auth.isAuthenticated) {
          await authLogin();
          return;
        }

        // Authed but not initialized - likely just logged in or hard refreshed.
        if (!initialized && auth.isAuthenticated) {
          // At this point we are authenticated and can set initialized,
          // we can start the silent renew service and continue rendering
          clearStaleStateDelayed();
          auth.startSilentRenew();
          setInitialized(true);
          return;
        }

        // For other cases e.g. not authenticated but is initialized
        // this would indicate a hard logout or silent renew failure, etc.
        // We can handle that in the event listeners and show the reauth dialog accordingly.
      };

      // Run on mount and when state changes.
      checkAuthStateAsync();

      // Event listeners for auth, silent renew, token expiry, etc.
      // We will manually handle the silent renew setup and cleanups here as we need for cross tab comms
      // Careful with start/stop the silent renew service or calling signInSilent twice in quick succession
      // as we don't want to cause double fetches to the server causing token issues due to timing.

      // There are more event listeners that can be added here. See auth.events in oidc-client-ts source.
      // Some events are dependent on monitorSession:true in userManager settings.
      // Also be mindful of side effects, like if you call removeUser somewhere else in the app
      // that could trigger an event listener here causing unintended side effects.
      // In testing all of the events fire reliably but are not emitted across tabs
      // so we need to use something like a BroadcastChannel to communicate between tabs

      // Access token is about to expire.
      // The silent renew service maybe be running depending on the state of the app at this point.
      // If the renew dialog was opened by a logout broadcast to other tabs, the silent renew service should have been stopped.
      // If there is no renew dialog then the silent renew service should still be running.
      const unsubAccessTokenExpiring = auth.events.addAccessTokenExpiring(() => {
        // Clear any stale state before the silent renew.
        // Should also keep the app storages clean as this basically runs on an interval.
        clearStaleStateDelayed();
      });

      // Access token has fully expired. Silent renew likely failed, network issues, etc.
      const unsubAccessTokenExpired = auth.events.addAccessTokenExpired(() => {
        // User may be AuthN in storage but AuthZ has most likely expired.
        auth.stopSilentRenew(); // Stop the silent renew service.
        clearStaleStateDelayed(); // Clear any stale states on interval too.
        // Show the reauth dialog if its not already open.
        if (!reauthDialogOpen) {
          setReauthDialog(reauthDialogStateExpired);
        }
      });

      // The silent renew service has encountered an error.
      const unsubSilentRenewError = auth.events.addSilentRenewError((error) => {
        // Stop the silent renew service if it encountered an error and show session expired dialog.
        auth.stopSilentRenew();
        if (!reauthDialogOpen) {
          setReauthDialog(reauthDialogStateExpired);
        }
      });

      // Cleanup listeners
      return () => {
        unsubAccessTokenExpiring();
        unsubAccessTokenExpired();
        unsubSilentRenewError();
      };
    }, [
      auth,
      auth.events,
      auth.signinSilent,
      location,
      setReauthDialog,
      reauthDialogOpen,
      reauthDialog,
      initialized,
      authLogin,
      authLogout,
      clearStaleStateDelayed,
    ]);

    /**
     * Determine splash message based on activeNavigator and auth state.
     * Note: When we had just auth.isLoading checks, sometimes during silent refreshes
     * the react tree would re-render and show the splash screen for a split second.
     * So use initialized state to prevent this unmouting and remounting of the react tree.
     */
    const determinedSplash = useMemo(() => {
      if (!initialized) {
        return 'Checking Authentication...'; // Await initialization completion
      }

      if (auth.isAuthenticated) {
        // Authenticated and initialized so a user is in storage.
        // AuthZ not guaranteed here but its ok to continue rendering.
        // Other event handlers will show the reauth dialog if needed.
        return null;
      }

      // auth.activeNavigator - Tracks the status of most recent signin/signout request method.
      // undefined | "signinRedirect" | "signinSilent" | "signinPopup" | "signoutSilent", etc...
      // Note certain activeNavigator states could cause the splash to show, causing the
      // provider to unmount the react tree and lose component state, so be mindful of those
      // and instead handle those situations by showing the reauth dialog.
      switch (auth.activeNavigator) {
        case 'signinRedirect': {
          return 'Redirecting to Signin...';
        }

        case 'signinSilent': {
          return null; // Silent refreshing, continue rendering
        }

        case 'signoutPopup':
        case 'signoutRedirect':
        case 'signoutSilent': {
          return 'Signing you out...';
        }

        // Nothing in progress or unhandled, continue rendering
        default: {
          return null;
        }
      }
    }, [auth, initialized]);

    // Context value for the provider
    const contextValue = useMemo(() => {
      return {
        envState: envState,
        reauthDialog: reauthDialog,
        setReauthDialog: setReauthDialog,
        authLogin: authLogin,
        authLogout: authLogout,
        clearStaleStateDelayed: clearStaleStateDelayed,
      };
    }, [
      envState,
      reauthDialog,
      setReauthDialog,
      authLogin,
      authLogout,
      clearStaleStateDelayed,
    ]);

    // Splash Screen for certain auth states
    if (determinedSplash) {
      return <BloxLoadingSplash message={determinedSplash} />;
    }

    return (
      <CosmosAuthEnvContext.Provider value={contextValue}>
        <ReauthDialog />
        {children}
      </CosmosAuthEnvContext.Provider>
    );
  },
);
WithAuthEnvChecksProvider.displayName = 'WithAuthEnvChecksProvider';

/**
 * Reauthenticate Dialog
 * This will be a better UX than redirecting the users current tab to a login page, or
 * unmounting the entire react tree to show the splash which causes loss of react state.
 *
 * Ensure the reauthenticate modal does not show up if the action was a manual logout
 * on the tab that initiated the logout. Only the other background tabs should show the reauth modal.
 * That can also be caused be via the checkAuth / checkError in the RA providers context.
 */
export const ReauthDialog = memo(() => {
  const auth = useAuth();
  const { reauthDialog, setReauthDialog, clearStaleStateDelayed } = useCosmosAuthEnv();
  const [error, setError] = useState<string | null>(null);

  const dialogOpen = Boolean(reauthDialog);

  const handleSignInPopup = async () => {
    try {
      const result = await auth.signinPopup();
      if (result) {
        auth.startSilentRenew(); // Re-enable silent renew after successful login
        clearStaleStateDelayed(); // Clear any stale state after successful login
        setReauthDialog(null); // Close the dialog after successful login
        setError(null); // Clear any previous errors
      } else {
        // User could have closed the popup or other network failure.
        throw new Error('Signin popup failed.', result);
      }
    } catch (error) {
      console.error('[ReauthDialog] handleSignInPopup error:', error);
      setError('There was an error. Please try again.');
    }
  };

  const handleOnClose = (event: React.SyntheticEvent<Element, Event>, reason: string) => {
    // Modal showing state is controlled by showReauthDialog.
    // User should not be able to close this modal without reauthenticating.
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return;
    }
  };

  return (
    <Dialog
      id="reauth-dialog"
      open={dialogOpen}
      onClose={handleOnClose}
      fullWidth={true}
      maxWidth="sm"
      aria-labelledby="reauth-dialog-title"
      aria-describedby="reauth-dialog-description"
      aria-live="assertive"
      sx={(theme) => ({
        '& .MuiDialog-paper': {
          borderRadius: 1,
          border: `1px solid ${theme.palette.divider}`,
          height: 225,
        },
        '& .MuiBackdrop-root': {
          backdropFilter: 'blur(4px) grayscale(100%);',
          backgroundColor: '#00000099',
        },
      })}
    >
      <DialogTitle
        id="reauth-dialog-title"
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: 1,
          backgroundColor: getSolidThemeGray(theme),
          borderBottom: `1px solid ${theme.palette.divider}`,
        })}
      >
        <WarningAmberRoundedIcon
          sx={(theme) => ({
            color: theme.palette.warning.main,
            fontSize: bloxValues.iconSizeRegular,
          })}
        />
        <Typography
          fontSize={bloxValues.xlFontSizeRem}
          fontWeight={bloxValues.fontWeightMedium}
        >
          {reauthDialog?.title}
        </Typography>
      </DialogTitle>
      <DialogContent
        id="reauth-dialog-description"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          p: 2,
          gap: 1,
        }}
      >
        <Typography
          pt={1}
          fontSize={bloxValues.xlFontSizeRem}
          fontWeight={bloxValues.fontWeightRegular}
        >
          {reauthDialog?.message}
        </Typography>
        <Typography
          fontSize={bloxValues.xlFontSizeRem}
          fontWeight={bloxValues.fontWeightRegular}
        >
          Please log in to continue.
        </Typography>
        <Typography
          fontSize={bloxValues.xlFontSizeRem}
          fontWeight={bloxValues.fontWeightRegular}
          color="error"
          display={error ? 'block' : 'none'}
        >
          {error}
        </Typography>
        <Button
          fullWidth={true}
          variant="contained"
          color="primary"
          title="Opens a new tab to re authenticate."
          onClick={handleSignInPopup}
          startIcon={<KeyIcon />}
          endIcon={<OpenInNewIcon />}
        >
          Log In {emdash} New Window
        </Button>
      </DialogContent>
    </Dialog>
  );
});
ReauthDialog.displayName = 'ReauthDialog';
